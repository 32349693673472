<template>
    <div class="worker">
        <div class="worker__inner">
            <h1 class="worker__heading">Сотрудник</h1>
            <p class="employee__info">Имя: {{worker.name}}</p>
            <p class="employee__info">Логин: {{worker.login}}</p>
            <p class="employee__info">Должность: {{worker.role}}</p>
            <div class="order-controlls">
                <form class="order-controlls__form" @submit.prevent="changeWorker">
                    <h2 class="order-controlls__subheading">Внести изменения</h2>
                    <label class="order-controlls__label" for="newName">Изменить имя:</label>
                    <input 
                        class="order-controlls__input" 
                        type="text" 
                        id="newName"
                        v-model="name">

                    <label class="order-controlls__label" for="newRole">Изменить должность:</label>
                    <select id="newRole" class="order-controlls__select" required v-model="role">
                        <option disabled selected value> -- Выберите должность -- </option>
                        <option>Инженер</option>
                        <option>Главный инженер</option>
                        <option>Склад</option>
                        <option>Бухгалтерия</option>
                        <option>Офис</option>
                        <option>Директор</option>
                        
                    </select>
                    <label class="order-controlls__label" for="newLogin">Изменить логин:</label>
                    <input 
                        class="order-controlls__input" 
                        type="text" 
                        id="newLogin"
                        v-model="login">
                    <button class="order-controlls__btn" @click="changeWorker">Применить изменения</button>
                </form>
                <div class="order-controlls__pass-controlls">
                    <span class="order-controlls__notation">*Пароль изменяется отдельно</span>
                    <label class="order-controlls__label" for="newPass">Изменить Пароль:</label>
                    <input 
                        id="newPass" 
                        type="text" 
                        class="order-controlls__input" 
                        placeholder="Введите новый пароль"
                        v-model="password">
                    <button class="order-controlls__btn" @click="changePass">Изменить пароль</button>

                    <button class="order-controlls__btn order-controlls__btn_delete" @click="delUser">Удалить пользователя</button>
                </div>
            </div>
            <router-link to="/employees" class="back">Назад</router-link>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            worker:{},
            name: '',
            login: '',
            role: '',
            password: ''
        }
    },
    methods: {
        fetchOneWorker(){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios.get(`https://superspiral.ru:3000/api/user/oneemployee/${this.$route.query.curworker}`,{
                headers: {Authorization: `Bearer ${cookie[1]}`},
            }).then((worker)=>{
                this.worker = worker.data
                this.name = worker.data.name
                this.role = worker.data.role
                this.login = worker.data.login
            })
        },
        changeWorker(){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios({
                method: 'PUT',
                url: 'https://superspiral.ru:3000/api/user/update',
                headers: {Authorization: `Bearer ${cookie[1]}`},
                params:{
                    id: this.worker.id,
                    login: this.login,
                    role: this.role,
                    name: this.name
                }
            }).catch((error)=>{
                switch (error.response.data.message) {
                    case 'Нет доступа':
                        alert(error.response.data.message)
                        break;
                
                    default:
                        break;
                }
            }).then(()=>{
                location.reload()
            })
        },
        changePass(){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios({
                method: 'PUT',
                url: 'https://superspiral.ru:3000/api/user/updatepass',
                headers: {Authorization: `Bearer ${cookie[1]}`},
                data:{
                    id: this.worker.id,
                    password: this.password
                }
            }).catch((error)=>{
                switch (error.response.data.message) {
                    case 'Нет доступа':
                        alert(error.response.data.message)
                        break;
                
                    default:
                        break;
                }
                console.log(error.response.data);
            }).then(()=>{
                location.reload()
            })
        },
        delUser(){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios.delete(`https://superspiral.ru:3000/api/user/delete/${this.$route.query.curworker}`,{
                headers: {Authorization: `Bearer ${cookie[1]}`},
            }).then(()=>{
                this.$router.push('/employees')
            })
        }
    },
    mounted() {
        this.fetchOneWorker()
    },
}
</script>

<style scoped>
    .worker{
        padding: 15px;
        max-width: 600px;
        margin: 0 auto;
    }
    .worker__inner{
        margin-top: 25px;
        padding: 20px;
        border: 1px solid #b8b8b8;
        box-shadow: 0 4px 21px rgb(0 0 0 / 25%);
        border-radius: 5px;
    }
    .employee__info{
        font-size: 18px;
    }
    .worker__heading{
        text-align: center;
        margin-bottom: 25px;
    }
    .order-controlls__form{
        border-top: 1px solid #000;
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        margin-bottom: 25px;
    }
    .order-controlls__subheading{
        margin-top: 25px;
        margin-bottom: 10px;
    }
    .order-controlls__pass-controlls{
        padding-top: 25px;
        border-top: 1px solid #000;
        display: flex;
        flex-direction: column;
    }
    .order-controlls__label{
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .order-controlls__input{
        padding: 10px;
        font-size: 14px;
        outline: none;
    }
    .order-controlls__select{
        -webkit-appearance: none;
        background-image: url(../../images/arrow.png);
        background-size: 25px 25px;
        background-repeat: no-repeat;
        background-position: 95% center;
        padding: 10px;
        outline: none;
        font-size: 14px;
        color: #000;
        border-radius: 2px;
    }
    .order-controlls__btn{
        border: 1px solid #009ED2;
        background: #009ED2;
        outline: none;
        color: #fff;
        border-radius: 2px;
        padding: 15px 0;
        font-size: 16px;
        font-weight: 700;
        margin-top: 50px;
    }
    .order-controlls__btn_delete{
        background-color: #D61921;
        border-color: #D61921;
    }
    .back{
        display: inline-block;
        padding: 15px 40px;
        background: #313131 url(../../images/arrow-2.png) no-repeat;
        background-position: center 85%;
        border-radius: 4px;
        color: #fff;
        text-decoration: none;
        margin-top: 20px;
    }
</style>