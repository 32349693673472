import {createRouter, createWebHistory} from 'vue-router'

import mainPage from '@/components/Main-page'
import adminForm from '@/components/admin/Admin-form'
import orderForm from '@/components/Order-form'
import orderPage from '@/components/Order-page'
import CurOrder from '@/components/Cur-order'
import Employees from '@/components/admin/Employees'
import Emoloyee from '@/components/admin/CurrentEmployee'
import ArchivePage from '@/components/Archive-page'
import AgentPage from '@/components/agents/Agents-page'

const routes =[
  {
    path: '/',
    name: 'main',
    component: orderPage
  },
  {
    path: '/create',
    component: adminForm
  },
  {
    path: '/order',
    component: orderForm
  },
  {
    path: '/login',
    component: mainPage
  },
  {
    path: '/curorder',
    name: 'curorder',
    component: CurOrder
  },
  {
    path: '/employees',
    component: Employees
  },
  {
    path: '/curworker',
    name: 'curworker',
    component: Emoloyee
  },
  {
    path: '/archive',
    name: 'archive',
    component: ArchivePage
  },
  {
    path: '/agents',
    component: AgentPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
