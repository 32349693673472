<template>
    <div class="main">
        <Login/>
    </div>
</template>

<script>
import Login from '@/components/Login'

export default {
    components:{
        Login
    }
}
</script>

<style>

</style>