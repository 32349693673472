<template>
    <div class="employees">
        <div class="employees__inner">
            <h1 class="employees__heading">Список сотрудников</h1>
            <div class="employee" v-for="employee in employees" :key="employee.id" @click="openUser(employee.id)">
                <p class="employee__info">Имя: {{employee.name}}</p>
                <p class="employee__info">Логин: {{employee.login}}</p>
                <p class="employee__info">Должность: {{employee.role}}</p>
            </div>
            <router-link to="/create" class="back">Назад</router-link>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            employees: ''
        }
    },
    methods: {
        
        getEmployees(){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios.get('https://superspiral.ru:3000/api/user/employees',{headers: {Authorization: `Bearer ${cookie[1]}`}}).then((res)=>{
                this.employees = res.data
            })
        },
        openUser(id){
            this.$router.push({name: 'curworker', query: {'curworker': id}})
        },
    },
    mounted() {
        this.getEmployees()
    },
}
</script>

<style scoped>
    .employees__inner{
        max-width: 600px;
        padding: 15px;
        margin: 0 auto;
    }
    .employee{
        margin-top: 25px;
        padding: 20px;
        border: 1px solid #b8b8b8;
        box-shadow: 0 4px 21px rgb(0 0 0 / 25%);
        border-radius: 5px;
    }
    .employees__heading{
        text-align: center;
        margin-top: 20px;
    }
    .employee__info{
        margin-bottom: 5px;
    }
    .back{
        display: inline-block;
        padding: 15px 40px;
        background: #313131 url(../../images/arrow-2.png) no-repeat;
        background-position: center 85%;
        border-radius: 4px;
        color: #fff;
        text-decoration: none;
        margin-top: 20px;
    }
</style>