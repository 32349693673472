<template>
    <div class="agent">
        <div class="agent__inner">
            <p class="agent__name">{{agent_data.name}}</p>
            <p class="agent__address">{{agent_data.address}}</p>
            <button 
                class="agent__delete"
                type="button"
                @click="deleteAgent"></button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: {
        agent_data: {
            type: Object,
            default(){
                return{

                }
            }
        },
    },
    methods: {
        deleteAgent(){
            let agree = confirm(`Удалить контрагента ${this.agent_data.name}`)
            if (agree) {
                let cookie = document.cookie.match(/token=(.+?)(;|$)/);
                axios.delete('https://superspiral.ru:3000/api/agent/remove',{
                    headers: {Authorization: `Bearer ${cookie[1]}`},
                    params: {
                        id: this.agent_data.id
                    }
                }).then(()=>{
                    location.reload()
                })
            }
        }
    },
}
</script>

<style scoped>
    .agent__inner{
        position: relative;
        padding: 20px 60px 20px 10px;
        border: 1px solid #000;
        border-radius: 5px;
    }
    .agent__delete{
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -15px;
        height: 30px;
        width: 30px;
        background: url(../../images/delete-ico.webp) no-repeat;
        background-size: cover;
    }
</style>