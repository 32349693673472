<template>
    <div class="select">
        <div class="select__inner">
            <p class="select__text">Статус:</p>
            <div class="select__body">
                <div 
                    class="select__current" @click="openSort"
                    :class="{rotate:isMenuOpened}">{{STATUS}}</div>

                <div class="select__items" v-if="isMenuOpened">
                    <span 
                        class="select__item" 
                        v-for="option in STATUSES"
                        :key="option"
                        @click="selectOption(option)"
                        :class="{disabled:option == STATUS}">{{option}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    data() {
        return {
            isMenuOpened: false
        }
    },
    computed:{
        ...mapGetters([
            'STATUS',
            'STATUSES'
        ])
    },
    methods: {
        ...mapActions([
            'GET_STATUS',
            'GET_ORDERS',
            'RESET_PAGE_NUMBER',
            'GET_ROUTER',
            'GET_ARCHIVE'
        ]),
        openSort(){
            this.isMenuOpened = !this.isMenuOpened
        },
        selectOption(option){
            if (this.$route.name == 'archive') {
                this.isMenuOpened = false
                this.GET_STATUS(option)
                this.RESET_PAGE_NUMBER()
                this.GET_ARCHIVE()
                return 
            }
            this.GET_STATUS(option)
            this.isMenuOpened = false
            this.RESET_PAGE_NUMBER()
            this.GET_ORDERS()
        }
    },
    mounted() {
        this.GET_ROUTER(this.$route.name)
    },
}
</script>

<style>
    .select__inner{
        display: flex;
        flex-direction: column;
        position: relative;
    }
    .select__text{
        margin-bottom: 10px;
    }
    .select__current{
        position: relative;
        padding: 13px 20px 13px 20px;
        border: 1px solid #000;
        border-radius: 2px;
        cursor: pointer;
    }
    .select__current::after{
        position: absolute;
        content: "";
        top: 30%;
        right: 20px;
        height: 15px;
        width: 15px;
        border-right: 2px solid #000;
        border-bottom: 2px solid #000;
        transform: rotate(45deg);
        transition: all linear .2s;
    }
    .select__items{
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 100%;
        right: 0;
        background-color: #EEE;
        padding: 0 10px;
        padding-bottom: 2px;
        width: 100%;
        z-index: 1000;
    }
    .select__item{
        padding: 20px 10px;
        border-bottom: 1px solid #000;
        cursor: pointer;
    }
    .select__item:hover{
        background-color: #d8d8d8;
    }
    .rotate::after{
        transform: rotate(225deg);
    }
    .disabled{
        display: none;
    }
</style>