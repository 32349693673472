import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
  state: {
      orders: [],
      currentMounth: '',
      selectedYear: '',
      ordersPerPage: 20,
      isArchive: false,
      page: 1,
      totalPages: 0,
      status: 'Все',
      user: 'Все',
      order: 'Все',
      statusOptions: ['Все', 'Зарегистрировано', 'Ожидает оплату', 'Оплачено', 'Готов к получению', 'В доставке', 'Доставлено', 'УПД подписано', 'УПД передано в бухгалтерию', 'УПД получено'],
      userOptions: ['Все'],
      orderOptions: ['Все', 'Производство', 'Директор', 'Бухгалтерия', 'Офис'],
      employees: '',
      agents: '',
      curRouter: '',
      sortSearch: 'num'
  },
  getters: {
      ORDERS(state){
        return state.orders
      },
      TOTAL_PAGES(state){
        return state.totalPages
      },
      USERS(state){
        return state.userOptions
      },
      STATUS(state){
        return state.status
      },
      ORDER(state){
        return state.order
      },
      USER(state){
        return state.user
      },
      STATUSES(state){
        return state.statusOptions
      },
      ORDERSELECT(state){
        return state.orderOptions
      },
      AGENTS(state){
        return state.agents
      }
  },
  mutations: {
      SET_ORDERS_TO_STATE: (state, orders)=>{
        state.orders = orders
      },
      SET_MOUNTH_TO_STATE:(state, value)=>{
        state.currentMounth = value
      },
      SET_ROLE_TO_STATE:(state, role)=>{
        state.role = role
      },
      SET_PAGE_NUMBER:(state, value)=>{
        state.page = value
      },
      SET_TOTAL_PAGES: (state, value)=>{
        state.totalPages = Math.ceil(value / state.ordersPerPage)
      },
      SET_DEFAULT_PAGE_NUMBER: (state, value)=>{
        state.page = value
      },
      SET_IS_ARCHIVE: (state, value)=>{
        state.isArchive = value
      },
      SET_STATUS:(state, value)=>{
        state.order = 'Все'
        state.user = 'Все'
        state.status = value
      },
      SET_USER:(state, value)=>{
        state.status = 'Все'
        state.order = 'Все'
        state.user = value
      },
      SET_ORDER:(state, value)=>{
        state.status = 'Все'
        state.user = 'Все'
        state.order = value
      },
      SET_EMPLOYEES:(state, value)=>{
        value.forEach(element => {
          state.userOptions.push(element.name)
        });
         
      },
      SET_AGENTS: (state, agents)=>{
        state.agents = agents
      },
      SET_ROUTER: (state, val)=>{
        state.curRouter = val
      },
      SET_SEARCH_SORT: (state, sort)=>{
        state.sortSearch = sort
      }
  },
  actions: {
      GET_ORDERS({commit}){
        let cookie = document.cookie.match(/token=(.+?)(;|$)/);
        return axios({
          method: 'GET',
          url: 'https://superspiral.ru:3000/api/order/all',
          headers: {Authorization: `Bearer ${cookie[1]}`},
          params: {
            page: this.state.page,
            status: this.state.status,
            user: this.state.user,
            order: this.state.order
          }
        }).then((orders)=>{
          commit('SET_ORDERS_TO_STATE', orders.data.rows);
          commit('SET_TOTAL_PAGES', orders.data.count);
          return orders;
        })
      },
      GET_ARCHIVE({commit}){
        let cookie = document.cookie.match(/token=(.+?)(;|$)/);
        return axios.get('https://superspiral.ru:3000/api/order/archive',{headers: {
          Authorization: `Bearer ${cookie[1]}`,
        }, params: {
            page: this.state.page,
            mounth: this.state.currentMounth,
            status: this.state.status,
            user: this.state.user,
            order: this.state.order
      },}).then((orders)=>{
          commit('SET_ORDERS_TO_STATE', orders.data.rows);
          commit('SET_TOTAL_PAGES', orders.data.count);
          return orders;
        })
      },
      SEARCH_CUR_ORDER({commit}, value){
        let cookie = document.cookie.match(/token=(.+?)(;|$)/);
        return axios({
          method: 'GET',
          url: 'https://superspiral.ru:3000/api/order/search',
          headers: { Authorization: `Bearer ${cookie[1]}`},
          params: {
              searchValue: value,
              isArchive: this.state.isArchive,
              sort: this.state.sortSearch
          }
      }).then((res)=>{
          commit('SET_ORDERS_TO_STATE', res.data);
          commit('SET_TOTAL_PAGES', 1);
      })
      },
      GET_EMPLOYEES({commit}){
        let cookie = document.cookie.match(/token=(.+?)(;|$)/);
        axios.get('https://superspiral.ru:3000/api/user/employees',{headers: {Authorization: `Bearer ${cookie[1]}`}}).then((res)=>{
          commit('SET_EMPLOYEES', res.data)
        })
      },
      GET_AGENTS({commit}){
        let cookie = document.cookie.match(/token=(.+?)(;|$)/);
        axios.get('https://superspiral.ru:3000/api/agent/getagents',{headers: {Authorization: `Bearer ${cookie[1]}`}}).then((res)=>{
          commit('SET_AGENTS', res.data)
        })
      },
      GET_MOUNTH({commit}, value){
        commit('SET_MOUNTH_TO_STATE', value)
      },
      GET_PAGE_NUMBER({commit}, value){
        commit('SET_PAGE_NUMBER', value)
      },
      RESET_PAGE_NUMBER({commit}, value){
        commit('SET_DEFAULT_PAGE_NUMBER', value)
      },
      IS_ARCHIVE({commit}, value){
        commit('SET_IS_ARCHIVE', value)
      },
      GET_STATUS({commit}, value){
        commit('SET_STATUS', value)
      },
      GET_ORDER({commit}, value){
        commit('SET_ORDER', value)
      },
      GET_USER({commit}, value){
        commit('SET_USER', value)
      },
      GET_ROUTER({commit}, val){
        commit('SET_ROUTER', val)
      },
      GET_SORT_SEARCH({commit}, sort){
        commit('SET_SEARCH_SORT', sort)
      }
  },
})
