<template>
  <div class="archive-page">
    <div class="archive-page__inner">
        <div class="header header__offset">
            <a 
                class="header__support" 
                href="https://t.me/+QCFmqwZEnl40M2Iy"
                target="_blank">Поддержка</a>
            <span class="header__role" @click="exit">{{userName}}</span>
        </div>
        <h1 class="archive-page__heading" id="top">Архив</h1>
        <div class="archive-page__calendar">
                <select 
                    class="order-calendar"
                    v-model="selectedYear"
                    @change="fetchPerMounth">
                    <option>2023</option>
                    <option>2024</option>
                    <option>2025</option>
                    <option>2026</option>
                </select>

                <select 
                    class="order-calendar"
                    v-model="selectedMounth"
                    @change="fetchPerMounth">
                    <option v-for="option in options" :value="option.value" :key="option.value">{{option.text}}</option>
                </select>
            </div>
            <div class="search">
                <div class="search-inner">
                    <input 
                        placeholder="Поиск..." 
                        class="search__field"
                        @input="getGuesses" 
                        @keyup.enter="search(searchValue)"
                        v-model="searchValue">
                <button @click="search(searchValue)" class="search__button"></button>
                </div>

                <div v-if="searchValue" class="search__guesses">
                    <div v-if="sortSearch == 'num'">
                        <p class="search__guess"
                        v-for="guess in guesses"
                        @click="selectGuess(guess.id)"
                        :key="guess.index">{{guess.id}}</p>
                    </div>

                    <div v-if="sortSearch == 'ypd'">
                        <p class="search__guess"
                        v-for="guess in guesses"
                        @click="selectGuess(guess.ypd)"
                        :key="guess.index">{{guess.ypd}}</p>
                    </div>

                    <div v-if="sortSearch == 'date'">
                        <p class="search__guess"
                        v-for="guess in guesses"
                        @click="selectGuess(guess.created)"
                        :key="guess.index">{{guess.created}}</p>
                    </div>
                </div>

                <div class="search__checkboxes">
                    <div class="checkbox checkbox__wrapper">
                        <span 
                            class="checkbox__ico"
                            :class="{checkbox_select: isNumber}"
                            @click="selectNumber"></span>
                        <input 
                            id="checkbox" 
                            type="checkbox" 
                            class="checkbox__input">

                        <label 
                            for="checkbox" 
                            class="checkbox__label"
                            @click="selectNumber">По номеру счета</label>
                    </div>
                    
                    <div class="checkbox checkbox__wrapper">
                        <span 
                            class="checkbox__ico"
                            :class="{checkbox_select: isYpd}"
                            @click="selectYpd"></span>
                        <input 
                            id="checkbox" 
                            type="checkbox" 
                            class="checkbox__input">

                        <label 
                            for="checkbox" 
                            class="checkbox__label"
                            @click="selectYpd">По номеру УПД</label>
                    </div>

                    <div class="checkbox checkbox__wrapper">
                        <span 
                            class="checkbox__ico"
                            :class="{checkbox_select: isDate}"
                            @click="selectDate"></span>
                        <input 
                            id="checkbox" 
                            type="checkbox" 
                            class="checkbox__input">

                        <label 
                            for="checkbox" 
                            class="checkbox__label"
                            @click="selectDate">По дате счета</label>
                    </div>
                </div>
            </div>

            <button 
                class="order-page__filters-btn"
                :class="{filterActive: isFiltersOpened}"
                type="button"
                @click="openFilters">Фильтры</button>

            <div class="order-page__filter" v-if="isFiltersOpened">
                <Filters/>
            </div>

            <Order 
                v-for="order in ORDERS"
                :key="order.id"
                :order_data="order"
                @openOrder="openOrder"
            />

            <div class="pagination archive-page__pagination">
                <a href="#top" 
                class="page"
                v-for="page in TOTAL_PAGES"
                :key="page"
                :class="{page__selected:page === this.$store.state.page}"
                @click="pageClick(page)">{{page}}</a>
            </div>

            <div class="navigation order-page__navigation">
                <router-link to="/" 
                    class="navigation__link navigation__link_now">Текущие</router-link>
                <router-link to="/order" class="navigation__link navigation__link_add">Добавить</router-link>
                <a href="#" 
                    class="navigation__link navigation__link_archive active">Архив</a>
            </div>
    </div>
  </div>
</template>

<script>
import Order from '@/components/Order'
import Filters from '@/components/filters/Filters'
import {mapActions ,mapGetters} from 'vuex'
import axios from 'axios'

export default {
    data() {
        return {
            selectedMounth: '02',
            selectedYear: '2024',
            date: '',
            userName: '',
            searchValue: '',
            guesses: '',
            isFiltersOpened: false,
            sortSearch: 'num',
            isNumber: true,
            isYpd: false,
            isDate: false,
            options: [
                {text: 'Январь', value: '01'},
                {text: 'Февраль', value: '02'},
                {text: 'Март', value: '03'},
                {text: 'Апрель', value: '04'},
                {text: 'Май', value: '05'},
                {text: 'Июнь', value: '06'},
                {text: 'Июль', value: '07'},
                {text: 'Август', value: '08'},
                {text: 'Сентябрь', value: '09'},
                {text: 'Октябрь', value: '10'},
                {text: 'Ноябрь', value: '11'},
                {text: 'Декабрь', value: '12'},
            ],
        }
    },
    components:{
        Filters,
        Order
    },
    methods: {
        ...mapActions([
            'GET_PAGE_NUMBER',
            'GET_ARCHIVE',
            'GET_MOUNTH',
            'RESET_PAGE_NUMBER',
            'SEARCH_CUR_ORDER',
            'IS_ARCHIVE',
            'GET_EMPLOYEES',
            'GET_SORT_SEARCH'
        ]),
        fetchPerMounth(){
            this.createDate()
            this.GET_MOUNTH(this.date)
            this.GET_ARCHIVE()
        },
        createDate(){
            this.date = `${this.selectedYear}-${this.selectedMounth}`
        },
        pageClick(page){
            this.GET_PAGE_NUMBER(page)
            this.GET_ARCHIVE()
        },
        openOrder(id){
            this.$router.push({name: 'curorder', query: {'curorder': id}})
        },
        exit(){
            let isExit = confirm("Выйти?");
            if(isExit){
                document.cookie = `token = ''; max-age=-1`
                this.$router.push('/login')
            } 
        },
        getRole(){
            this.userName = localStorage.getItem('name')
        },
        search(value){
            this.SEARCH_CUR_ORDER(value)
            this.searchValue = ''
        },
        getGuesses(){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios({
                method: 'GET',
                url: 'https://superspiral.ru:3000/api/order/getguess',
                headers: { Authorization: `Bearer ${cookie[1]}`},
                params: {
                    searchVal: this.searchValue,
                    sort: this.sortSearch,
                    isArchive: 1
                }
            }).then((res)=>{
                this.guesses = res.data
            })
        },
        selectGuess(id){
            this.searchValue = id
        },
        openFilters(){
            this.isFiltersOpened = !this.isFiltersOpened
        },
        selectNumber(){
            this.isNumber = true
            this.isYpd = false
            this.isDate = false
            this.sortSearch = 'num'
            this.GET_SORT_SEARCH('num')
        },
        selectYpd(){
            this.isYpd = true
            this.isNumber = false
            this.isDate = false
            this.sortSearch = 'ypd'
            this.GET_SORT_SEARCH('ypd')
        },
        selectDate(){
            this.isDate = true
            this.isYpd = false
            this.isNumber = false
            this.sortSearch = 'date'
            this.GET_SORT_SEARCH('date')
        }
    },
    computed: {
        ...mapGetters([
            'ORDERS',
            'TOTAL_PAGES'
        ])
    },
    mounted() {
        this.IS_ARCHIVE(1)
        this.RESET_PAGE_NUMBER(1)
        this.fetchPerMounth();
        this.GET_EMPLOYEES()
        this.getRole();
        window.scrollTo(0, 0)
    },
    
}
</script>

<style scoped>
    .archive-page__inner{
        padding: 10px 15px;
        padding-bottom: 80px;
        max-width: 600px;
        margin: 0 auto;
        border: 2px solid #000;
        border-radius: 2px;
        margin-top: 15px;
    }
    .archive-page__heading{
        text-align: center;
        margin-top: 15px;
    }
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #000;
    }
    .header__offset{
        padding-bottom: 15px;
    }
    .header__support{
        position: relative;
        padding-top: 40px;
        font-size: 12px;
        color: #000;
        text-decoration: none;
    }
    .header__support::before{
        position: absolute;
        content: "";
        height: 40px;
        width: 40px;
        top: 0;
        left: 15px;
        background: url(../images/support-ico.png) no-repeat;
        background-size: cover;
    }
    .header__role{
        position: relative;
        padding: 15px 45px 25px 0;
    }
    .header__role::after{
        position: absolute;
        content: "";
        height: 35px;
        width: 35px;
        right: 0;
        top: 50%;
        margin-top: -20px;
        background: url(../images/exit.png) no-repeat;
        background-size: contain;
    }
    .archive-page__calendar{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
    }
    .order-calendar{
        width: 180px;
        -webkit-appearance: none;
        background-image: url(../images/arrow.png);
        background-size: 25px 25px;
        background-repeat: no-repeat;
        background-position: 95% center;
        padding: 10px;
        outline: none;
        font-size: 14px;
        color: #000;
        border-radius: 2px;
    }
    .search{
        position: relative;
        height: 100%;
        width: 100%;
    }
    .search-inner{
        display: flex;
        border: 1px solid #000;
        border-radius: 5px;
        margin-top: 20px;
        margin-bottom: 25px;
    }
    .search__field{
        border: none;
        border-radius: 5px 0 0 5px;
        padding: 15px 25px 15px 20px;
        width: 100%;
        height: 100%;
        transition: all linear .1s;
    }
    .search__field:focus{
        outline: none;
    }
    .search__button{
        background: none;
        border: none;
        border-left: 1px solid #000;
        width: 55px;
        background: url(../images/search-ico.png) no-repeat;
        background-position: center;
        background-size: 30px 30px;
        border-radius: 0 5px 5px 0;
    }
    .search__button:hover{
        cursor: pointer;
    }
    .search__guesses{
        position: absolute;
        background-color: #fff;
        box-shadow: 0 10px 20px rgb(0 0 0 / 50%);
        padding: 15px;
        width: 100%;
        z-index: 100;
        max-height: 330px;
        overflow-y: scroll;
    }
    .search__guess{
        margin-top: 15px;
        font-size: 14px;
        padding: 5px;
    }
    .search__guess:hover{
        cursor: pointer;
        background-color: #b6b4b4;
    }
    .pagination{
        display: flex;
        justify-content: center;
    }
    .archive-page__pagination{
        margin-top: 25px;
        margin-bottom: 25px;
    }
    .page{
        height: 40px;
        width: 40px;
        line-height: 40px;
        background-color: #D8D8D8;
        text-align: center;
        margin-right: 10px;
        transition: all linear .2s;
        border-radius: 5px;
        color: #000;
        text-decoration: none;
    }
    .page:hover{
        cursor: pointer;
        background-color: #b6b4b4;
    }
    .page__selected{
        background-color: #b6b4b4;
    }
    .order-page__navigation{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        background-color: #eee;
        border-top: 1px solid #d8d8d8;
    }
    .navigation__link{
        flex-basis: 33.333%;
        position: relative;
        padding: 50px 25px 10px;
        font-size: 12px;
        text-decoration: none;
        color: #000;
        border-right: 1px solid #d8d8d8;
        text-align: center;
    }
    .navigation__link::before{
        position: absolute;
        content: "";
        width: 40px;
        height: 40px;
        top: 5px;
        left: 50%;
        margin-left: -20px;
    }
    .navigation__link:last-child{
        border-right: none;
    }
    .navigation__link_now::before{
        background: url(../images/now2.png) no-repeat;
        background-size: contain;
        background-position: center;
    }
    .navigation__link_add::before{
        background: url(../images/add4.png) no-repeat;
        background-size: contain;
        background-position: center;
    }
    .navigation__link_archive::before{
        background: url(../images/archive.png) no-repeat;
        background-size: contain;
        background-position: center;
    }
    .active{
        background-color: #d8d8d8;
        pointer-events: none;
    }
    .order-page__filters-btn{
        background: none;
        font-size: 14px;
        padding: 15px 30px;
        border: 1px solid #000;
        margin-bottom: 15px;
        margin-top: 30px;
    }
    .order-page__filters-btn:hover{
        background-color: #d8d8d8;
    }
    .search__checkboxes{
        display: flex;
        justify-content: space-between;
    }
    @media (max-width: 600px) {
        .search__checkboxes{
            flex-direction: column;
        }
        .checkbox__wrapper{
            margin-bottom: 25px;
        }
    }
</style>