<template>
    <div class="login">
        <div class="login__inner">
            <form class="form login__form" @submit="sendForm" onsubmit="return false;">
                <h1 class="login__heading">Авторизация</h1>
                <img class="form__logo" src="../images/logo.png">
                <label for="login" class="form__label">Логин</label>
                <input 
                    type="text" 
                    placeholder="Логин"
                    class="form__input"
                    id="login"
                    v-model="form.login">

                <label for="password" class="form__label">Пароль</label>
                <input 
                    type="password" 
                    placeholder="Пароль" 
                    id="password"
                    class="form__input"
                    v-model="form.password">

                <button type="submit" class="form__btn">Войти</button>
            </form>
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            form:{
                login: '',
                password: ''
            }
        }
    },
    methods: {
        sendForm(){
            axios({
                method: 'POST',
                url: 'https://superspiral.ru:3000/api/user/login',
                data: {
                    login: this.form.login,
                    password: this.form.password
                }
            }).then((response)=>{
                document.cookie = `token = ${response.data.token}; max-age=259200`
                localStorage.setItem('role', response.data.role)
                localStorage.setItem('name', response.data.name)
                switch (response.data.message) {
                    case 'Такой пользователь не найден':
                        alert('Неверный логин')
                        break;
                    case 'Неверный пароль':
                        alert(response.data.message)
                        break;
                    default:
                        this.$router.push('/')
                        break;
                }
            })
        }
    },
}
</script>

<style scoped>
    .login__inner{
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .login__form{
        max-width: 600px;
    }
    .form{
        display: flex;
        flex-direction: column;
        padding: 20px;
        border: 1px solid #b8b8b8;
        box-shadow: 0 4px 21px rgb(0 0 0 / 25%);
        border-radius: 5px;
    }
    .login__heading{
        text-align: center;
        margin-bottom: 20px;
    }
    .form__logo{
        max-width: 150px;
        align-self: center;
        margin-bottom: 20px;
    }
    .form__label{
        margin-top: 20px;
        margin-bottom: 10px;
        font-size: 16px;
    }
    .form__input{
        padding: 10px;
        font-size: 14px;
        outline: none;
        border-radius: 2px;
    }
    .form__btn{
        border: 1px solid #000;
        background: #000;
        outline: none;
        color: #fff;
        border-radius: 2px;
        padding: 15px 0;
        font-size: 16px;
        font-weight: 700;
        margin-top: 25px;
    }
</style>