<template>
    <div class="full-order">
        <div class="full-order__inner">
            <h1 class="full-order__heading">Счет {{order.id}}</h1>
            <p class="full-order__date">От: {{order.created}}</p>
            <p class="full-order__ypd">Номер УПД: {{order.ypd}}</p>
            <p class="full-order__ypd-date">От: {{order.ypdCreatedBy}}</p>
            <div class="full-order__files">
                <div class="full-order__btns"  v-if="order.url !== null">
                    <button 
                        @click="download(order.url)" 
                        class="download-btn full-order__download">Скачать файл счета</button>
                    <button class="download-btn full-order__show" @click="openFile(order.url)">Посмотреть</button>
                </div>
                    
                
                <div class="full-order__btns" v-if="order.url2 !== null">
                    <button 
                        @click="download(order.url2)" 
                        class="download-btn full-order__download_second"
                        >Скачать файл платежного поручения</button>

                        <button class="download-btn full-order__show" @click="openFile(order.url2)">Посмотреть</button>
                </div>
                
                <div class="full-order__btns" v-if="order.url3 !== null">
                    <button 
                        @click="download(order.url3)" 
                        class="download-btn full-order__download_second"
                        >Скачать файл УПД</button>

                        <button class="download-btn full-order__show" @click="openFile(order.url3)">Посмотреть</button>
                </div>
                

                <div class="full-order__add-btns">
                    <button 
                        class="full-order__addfile"
                        @click="addFileOne"
                        v-if="fileMain !== ''"
                        :class="{btn__hide: order.isArchive && userRole !=='ADMIN', loading: isLoading}"
                        >Загрузить {{fileNameMain}}</button>

                    <button 
                        class="full-order__addfile"
                        @click="addFileTwo"
                        v-if="file !== ''"
                        :class="{btn__hide: order.isArchive && userRole !=='ADMIN', loading: isLoading}"
                        >Загрузить {{fileName}}</button>
                
                    <button 
                        class="full-order__addfile"
                        @click="addFileThree"
                        v-if="file2 !== ''"
                        :class="{btn__hide: order.isArchive && userRole !=='ADMIN', loading: isLoading}"
                        >Загрузить {{fileName2}}</button>
                </div>
                        
                <div class="full-order__aside">
                    <label v-if="order.url == null && userRole == 'ADMIN'" for="fileMain"  class="full-order__label">Добавить файл счета:</label>
                    <input 
                        type="file" 
                        id="fileMain" 
                        class="full-order__file"
                        ref="fileMain"
                        @change="handleMainUpload">

                    <label v-if="order.url2 == null && isVisibleAdd && !order.isArchive ||order.url2 == null && userRole == 'ADMIN'" for="file"  class="full-order__label">Добавить платежное поручение:</label>
                    <input 
                        type="file" 
                        id="file" 
                        class="full-order__file"
                        ref="file2"
                        @change="handleUploadFile">

                    <label v-if="order.url3 == null && isVisibleAdd2 && !order.isArchive && !order.isService || order.url3 == null && userRole == 'ADMIN'" for="file2"  class="full-order__label">Добавить УПД:</label>
                    <input 
                        type="file" 
                        id="file2" 
                        class="full-order__file"
                        ref="file3"
                        @change="handleUPDupload">
                </div>
            </div>

            <div class="admin-controlls admin-controlls_offset" v-if= "userRole=='ADMIN'">
                <button 
                    class="admin-controlls__btn" 
                    @click="deleteFile(order.url, 1)"
                    v-if="order.url">Удалить файл счета</button>

                <button 
                    class="admin-controlls__btn" 
                    @click="deleteFile(order.url2, 2)"
                    v-if="order.url2">Удалить файл платежного поручения</button>

                <button 
                    class="admin-controlls__btn" 
                    @click="deleteFile(order.url3, 3)"
                    v-if="order.url3">Удалить файл УПД</button>
            </div>

            <form class="ypd full-order__addYpd" @submit.prevent="addYpd" v-if="!order.ypd">
                <span style="font-size: 14px;">*Файл УПД добавляется отдельно</span>
                <input class="ypd__input" type="text" placeholder="Введите номер упд" v-model="ypdnumber" required>
                <input 
                    id="ypddate" 
                    type="date" 
                    v-model="ypdCreatedDate" 
                    class="ypd__date"
                    required>
                <button class="full-order__btn ypd__btn" type="submit" :class="{loading: isLoading}">Добавить номер упд</button>
            </form>

            <div class="agent full-order__agent" v-if="agent">
                <p class="agent__name">Контрагент: <span class="agent__mark">{{agent.name}}</span></p>
                <p class="agent__address">Инфо: <span class="agent__mark">{{agent.address}}</span></p>
                <button 
                    type="button" 
                    class="agent__show"
                    @click="toggleAgentForm"
                    v-if="!order.isArchive"
                    ></button>

                <div class="agent-form agent__edit-form" v-if="isAgentEdit">
                    <div class="agent order-form__agent">
                    <div 
                        class="agent__current" @click="openMenu"
                        :class="{rotate:isMenuOpened}">{{agentName}}</div>

                    <div class="agent__items" v-if="isMenuOpened">
                        <span 
                            class="agent__item" 
                            v-for="option in AGENTS"
                            :key="option.id"
                            @click="selectOption(option)"
                            :class="{disabled:option.name == selectedAgent.name}"
                            >{{option.name}}</span>
                        <span 
                            class="agent__item"
                            @click="selectOption('')"
                            :class="{disabled:selectedAgent.name == 'Контрагент не выбран'}"
                            >Не выбран</span>
                    </div>
                </div>
                    <button 
                        class="agent-form__btn btn"
                        :class="{loading: isLoading}"
                        @click="editAgent">Применить</button>
                </div>
            </div>

            <div class="full-order__delivery-wrapper">
                <label for="edit" class="full-order__delivery-label" v-if="!order.isArchive"></label>
                <input 
                    type="checkbox" 
                    id="edit" 
                    class="full-order__checkbox"
                    v-model="editInfo">
                <p class="full-order__delivery">Информация о доставке:</p>
                <p class="full-order__info" v-if="!editInfo">{{order.info}}</p>
                <textarea 
                    v-model="info" 
                    rows="5"
                    class="full-order__edit" 
                    v-else-if="editInfo && !order.isArchive"></textarea>
                <button 
                    class="full-order__btn full-order__apply" 
                    @click="changeInfo"
                    v-if="editInfo"
                    :class="{loading: isLoading}">Изменить</button>
            </div>
                

            <p class="full-order__status">Текущий статус: {{order.status}}</p>
                <button 
                    class="full-order__btn" 
                    @click="changeStatus"
                    v-if="isStatusBtnVisible && !order.isArchive"
                    :class="{btn__disabled:nextStatus==undefined}">Изменить статус на {{nextStatus}}</button>

            <h2 class="full-order__subheading">История статусов:</h2>
            <div class="full-order__history">
                <p class="full-order__change">Зарегистрирован - {{order.createdBy}}</p>
                <p class="full-order__change">{{order.changedBy1}}</p>
                <p class="full-order__change">{{order.changedBy2}}</p>
                <p class="full-order__change">{{order.changedBy9}}</p>
                <p class="full-order__change">{{order.changedBy3}}</p>
                <p class="full-order__change">{{order.changedBy4}}</p>
                <p class="full-order__change">{{order.changedBy5}}</p>
                <p class="full-order__change">{{order.changedBy6}}</p>
                <p class="full-order__change">{{order.changedBy7}}</p>
                <p class="full-order__change">{{order.changedBy8}}</p>
            </div>
                
            <button
                v-if="userRole !== 'Бухгалтерия'" 
                class="full-order__btn full-order__btn_red" 
                @click="deleteOrder"
                :class="{btn__show:isDeleteBtnActive, btn__showAdm:isAdmin}">Удалить счет</button>
            <div class="controlls">
                <router-link v-if="!order.isArchive" to="/" class="back">Назад</router-link>
                <router-link v-else to="/archive" class="back">Назад</router-link>
                <button 
                    class="controlls__btn" 
                    @click="setToArchive"
                    v-if="!order.isArchive && userRole =='Бухгалтерия'"
                    :class="{btn__hide: order.isArchive}"
                    >Закрыть счет</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'
import fileDownload from 'js-file-download'

export default {
    data() {
        return {
            isAgentEdit: false,
            fileNameMain: '',
            fileName: '',
            fileName2: '',
            fileMain: '',
            file: '',
            file2: '',
            order: {},
            currentStatus: '',
            nextStatus: '',
            currentStatusIndex: '',
            userRole: '',
            info: '',
            ypdnumber: '',
            ypdCreatedDate: '',
            editInfo: false,
            isDeleteBtnActive: false,
            isVisibleAdd: false,
            isVisibleAdd2: false,
            isStatusBtnVisible: false,
            isSetToArchive: false,
            isAdmin: false,
            isLoading: false, 
            isMenuOpened: false,
            selectedAgent: '',
            statuses: [
                'Зарегистрировано',
                'Ожидает оплату',
                'Оплачено',
                'Готов к получению',
                'В доставке',
                'Доставлено',
                'УПД подписано',
                'УПД передано в бухгалтерию',
                'УПД получено' 
            ],
            agent: ''
        }
    },
    computed: {
        ...mapGetters([
            'AGENTS'
        ]),
        submitBtnText(){
            return this.isLoading ? 'Загрузка...' : 'Создать'
        },
        agentName(){
            return this.selectedAgent == '' ? 'Контрагент не выбран' : this.selectedAgent.name
        }
    },
    methods: {
        ...mapActions([
            'GET_AGENTS'
        ]),
        toggleAgentForm(){
            this.isAgentEdit = !this.isAgentEdit
        },
        getOneOrder(){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios.get(`https://superspiral.ru:3000/api/order/`,{
                headers: {Authorization: `Bearer ${cookie[1]}`},
                params: {
                    id: this.$route.query.curorder
                }
            }).then((order)=>{
                if (order.data.agentId) {
                    this.agent = JSON.parse(order.data.agentId)
                }
                this.order = order.data
                this.currentStatus = order.data.status
                this.info = order.data.info
                this.userRole = localStorage.getItem('role')
                if(localStorage.getItem('role') == 'ADMIN'){
                    this.isAdmin = true
                }
            }).then(()=>{
                this.setNextStatus()
                this.checkRole()
            })
        },
        handleMainUpload(){
            this.fileNameMain = this.$refs.fileMain.files[0].name
            this.fileMain = this.$refs.fileMain.files[0]
        },
        handleUploadFile(){
            this.fileName = this.$refs.file2.files[0].name
            this.file = this.$refs.file2.files[0]
        },
        handleUPDupload(){
            this.fileName2 = this.$refs.file3.files[0].name
            this.file2 = this.$refs.file3.files[0]
        },
        changeInfo(){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            this.isLoading = true
            axios({
                method: 'PUT',
                url: 'https://superspiral.ru:3000/api/order/edit',
                headers: { Authorization: `Bearer ${cookie[1]}`, 'Content-Type' : 'application/json' },
                data: {
                    id: this.order.index,
                    info: this.info
                }
            }).then(()=>{
                location.reload()
            })
        },
        editAgent(){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            this.isLoading = true
            let agentToStr = JSON.stringify(this.selectedAgent)
            axios({
                method: 'PUT',
                url: 'https://superspiral.ru:3000/api/order/editAgent',
                headers: { Authorization: `Bearer ${cookie[1]}`, 'Content-Type' : 'application/json' },
                data: {
                    id: this.order.index,
                    agent: agentToStr
                }
            }).then(()=>{
                location.reload()
            })
        },
        //////////////////////////////////////////////////
        addYpd(){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            this.isLoading = true
            axios({
                method: 'PUT',
                url: 'https://superspiral.ru:3000/api/order/addypd',
                headers: { Authorization: `Bearer ${cookie[1]}`, 'Content-Type' : 'application/json' },
                data: {
                    id: this.order.index,
                    ypd: this.ypdnumber,
                    ypdCreatedBy: this.ypdCreatedDate
                }
            }).then(()=>{
                location.reload()
            })
        },
        openFile(orderUrl){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios.get(`https://superspiral.ru:3000/api/order/showfile`,{
                headers: {Authorization: `Bearer ${cookie[1]}`},
                responseType: 'blob',
                params:{
                    url: orderUrl
                }
            }).then((res)=>{
                let url = URL.createObjectURL(res.data)
                let link = document.createElement('a')
                link.href = url
                link.target = "_blank"
                window.open(link)
            })
        },
        addFileOne(){
            this.isLoading = true
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            const formData = new FormData()
            formData.append('url', this.fileMain)
            axios({
                method: 'PUT',
                url: 'https://superspiral.ru:3000/api/order/uploadmain',
                headers: { Authorization: `Bearer ${cookie[1]}`, "Content-Type": "multipart/form-data" },
                data: formData,
                params:{
                    id: this.order.index
                }
            }).then(()=>{
                location.reload()
            })
        },
        addFileTwo(){
            this.isLoading = true
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            const formData = new FormData()
            formData.append('url2', this.file)
            axios({
                method: 'PUT',
                url: 'https://superspiral.ru:3000/api/order/upload',
                headers: { Authorization: `Bearer ${cookie[1]}`, "Content-Type": "multipart/form-data" },
                data: formData,
                params:{
                    id: this.order.index
                }
            }).then(()=>{
                location.reload()
            })
        },
        addFileThree(){
            this.isLoading = true
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            const formData = new FormData()
            formData.append('url3', this.file2)
            axios({
                method: 'PUT',
                url: 'https://superspiral.ru:3000/api/order/uploadfile',
                headers: { Authorization: `Bearer ${cookie[1]}`, "Content-Type": "multipart/form-data" },
                data: formData,
                params:{
                    id: this.order.index
                }
            }).then(()=>{
                location.reload()
            })
        },
        changeStatus(){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios({
                method: 'PUT',
                url: 'https://superspiral.ru:3000/api/order/status',
                headers: {Authorization: `Bearer ${cookie[1]}`},
                data: {
                    id: this.order.id,
                    index: this.order.index,
                    status: this.nextStatus
                }
            }).then(()=>{
                if(this.currentStatus == 'УПД передано в бухгалтерию'){
                    return this.$router.push('/')
                }
                location.reload()
            })
        },
        deleteOrder(){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios({
                method: 'DELETE',
                url: 'https://superspiral.ru:3000/api/order/delete',
                headers: {Authorization: `Bearer ${cookie[1]}`},
                params: {
                    id: this.order.index,
                    status: this.order.status
                }
            }).then(()=>{
                this.$router.push('/')
            }).then(()=>{
                axios({
                    method: 'DELETE',
                    url: `https://superspiral.ru:3000/api/order/removefile/${this.order.url}`,
                    headers: {Authorization: `Bearer ${cookie[1]}`},
                })
            }).then(()=>{
                if(this.userRole === 'ADMIN'){
                    axios({
                    method: 'DELETE',
                    url: 'https://superspiral.ru:3000/api/order/removeallfiles',
                    headers: {Authorization: `Bearer ${cookie[1]}`},
                    data:{
                        url2: this.order.url2,
                        url3: this.order.url3
                    }
                })
                }
            })
        },
        deleteFile(fileUrl, urlId){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            let removeFile = confirm("Вы уверены?");
            if(removeFile){
                axios({
                    method: 'PUT',
                    url: `https://superspiral.ru:3000/api/order/admindelete`,
                    headers: {Authorization: `Bearer ${cookie[1]}`},
                    params: {
                        id: this.order.index,
                        url: fileUrl,
                        fileId: urlId
                    }
                }).then(()=>{
                    location.reload()
                })
            }
        },
        setNextStatus(){
            let index = this.statuses.indexOf(this.currentStatus) +1
            this.currentStatusIndex = index -1
            this.nextStatus = this.statuses[index]
            if(this.order.withoutDelivery && this.currentStatus == 'Оплачено'){
                return this.nextStatus = 'УПД подписано'
            }
            if(this.order.isService && this.currentStatus == 'Оплачено'){
                return this.nextStatus = 'УПД получено'
            }
        },
        download(orderUrl){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            return axios({
                method: "GET",
                url: 'https://superspiral.ru:3000/api/order/downloadfile',
                headers: {Authorization: `Bearer ${cookie[1]}`},
                responseType: 'blob',
                params:{
                    url: orderUrl
                }
            }).then((res)=>{
                fileDownload(res.data, orderUrl)
            })
        },
        checkRole(){
            let role = localStorage.getItem('role')
            if(role == 'ADMIN' || this.currentStatusIndex < 1 && !this.order.isArchive){
                this.isDeleteBtnActive = true
            }
            if(this.currentStatus === 'Заархивировано'){
                this.isStatusBtnVisible = false
                return
            }
            if(this.currentStatus == 'Оплачено' && this.nextStatus !== 'УПД подписано' && this.nextStatus !== 'УПД получено'){
                this.isStatusBtnVisible = true
            }
            if(role == 'Офис' && this.currentStatus !== 'Ожидает оплату' || role == 'Директор' && this.currentStatus !== 'Ожидает оплату'){
                this.isStatusBtnVisible = true
            }
            if(role == 'Офис' && this.currentStatus == 'УПД передано в бухгалтерию' || role == 'Директор' && this.currentStatus == 'УПД передано в бухгалтерию'){
                return this.isStatusBtnVisible = false
            }
            if(role == 'Бухгалтерия' || role == 'Офис' || role == 'Директор' || role == 'ADMIN'){
                this.isVisibleAdd = true
            }
            if(role !== 'Бухгалтерия'){
                this.isVisibleAdd2 = true
            }
            if (role == 'Главный инженер' && this.currentStatus == 'Зарегистрировано') {
                this.isStatusBtnVisible = true
            }
            if (role == 'Бухгалтерия' && this.currentStatus == 'Ожидает оплату') {
                this.isStatusBtnVisible = true
            }
            if (role == 'Склад' && this.nextStatus !== 'УПД получено') {
                this.isStatusBtnVisible = true
            }
            if (role == 'Главный инженер' && this.nextStatus == 'УПД подписано' || role == 'Инженер' && this.nextStatus == 'УПД подписано') {
                this.isStatusBtnVisible = true
            }
            if (role == 'Бухгалтерия' && this.nextStatus == 'УПД получено') {
                this.isStatusBtnVisible = true
            }
        },
        setToArchive(){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            let close = confirm("Вы уверены?");
            if(close){
                axios({
                    method: 'PUT',
                    url: 'https://superspiral.ru:3000/api/order/closeorder',
                    headers: {Authorization: `Bearer ${cookie[1]}`},
                    params: {
                        id: this.order.index,
                    }
                }).then(()=>{
                    this.$router.push('/')
                })
            }  
        },
        selectOption(option){
            this.selectedAgent = option
            this.isMenuOpened = false
        },
        openMenu(){
            this.isMenuOpened = !this.isMenuOpened
        }
    },

    mounted() {
        this.getOneOrder()
        window.scrollTo(0, 0)
        this.GET_AGENTS()
    },
}
</script>

<style scoped>
    .full-order{
        padding: 0 15px;
        max-width: 600px;
        margin: 0 auto;
    }
    .full-order__inner{
        width: 100%;
        max-width: 600px;
        margin: 25px 0;
        padding: 10px;
        box-shadow: 4px 4px 10px rgb(0 0 0 / 25%);
        border: 1px solid #000;
        border-radius: 5px;
        background-color: #EEE;
    }
    .full-order__aside{
        display: flex;
        flex-direction: column;
    }
    .full-order__date{
        margin-bottom: 15px;
    }
    .full-order__ypd-date{
        margin-top: 5px;
        margin-bottom: 20px;
    }
    .full-order__files{
        display: flex;
        flex-direction: column;
    }
    .full-order__label{
        position: relative;
        padding: 10px 55px 10px 0;
        display: inline-block;
        margin-top: 25px;
    }
    .full-order__label::after{
        position: absolute;
        content: "";
        width: 40px;
        height: 40px;
        background: url(../images/add.png) no-repeat;
        background-size: cover;
        bottom: 0;
        right: 0;
    }
    .full-order__file{
        position: absolute;
        z-index: -1;
        transform: scale(0);
    }
    .full-order__delivery-wrapper{
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 30px;
        margin-bottom: 20px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        position: relative;
    }
    .full-order__delivery-label{
        position: absolute;
        display: block;
        height: 40px;
        width: 40px;
        right: 0;
        top: 10px;
        background: url(../images/edit-ico.png) no-repeat;
        background-size: contain;
    }
    .full-order__checkbox{
        display: none;
        transform: scale(0);
    }
    .full-order__info{
        margin-top: 10px;
    }
    .full-order__edit{
        padding: 5px;
        font-size: 16px;
        margin-top: 15px;
        width: 100%;
    }
    
    .full-order__status{
        margin-bottom: 10px;
        font-weight: 700;
    }
    .full-order__btns{
        display: flex;
        justify-content: space-between;
    }
    .full-order__btn{
        margin-bottom: 20px;
        width: 100%;
        border: 1px solid #009ED2;
        background: #009ED2;
        outline: none;
        color: #fff;
        border-radius: 2px;
        padding: 15px 0;
        font-size: 16px;
    }
    .full-order__addYpd{
        margin-top: 30px;
        margin-bottom: 20px;
    }
    .admin-controlls_offset{
        margin-top: 25px;
    }
    .admin-controlls{
        display: flex;
        justify-content: space-between;
    }
    .admin-controlls__btn{
        width: 30%;
        color: #fff;
        background-color: #D61921;
        border: 1px solid #D61921;
        border-radius: 5px;
        padding: 10px;
        height: 80px;
        outline: none;
        border-radius: 5px;
        margin-right: 10px;
        max-width: 140px;
    }
    .admin-controlls__btn:last-child{
        margin-right: 0;
    }
    .ypd{
        display: flex;
        flex-direction: column;
    }
    .ypd__input{
        padding: 10px 5px;
        margin-bottom: 20px;
    }
    .ypd__date{
        padding: 10px 5px;
        margin-bottom: 20px;
    }
    .ypd__btn{
        border-color: #c4c703;
        background-color: #c4c703;
    }
    .full-order__apply{
        margin-top: 10px;
        margin-bottom: 0;
        border-color: #0F9446;
        background-color: #0F9446;
    }
    .controlls{
        display: flex;
        justify-content: space-between;
    }
    .controlls__btn{
        border: none;
        outline: none;
        padding: 15px 40px;
        background-color: #313131;
        border-radius: 4px;
        color: #fff;
        margin-left: 10px;
        font-size: 16px;
        font-family: 'Montserrat', sans-serif;
    }
    .download-btn{
        width: 140px;
        color: #fff;
        background-color: #0F9446;
        border: 1px solid #d8d8d8;
        border-radius: 5px;
        height: 80px;
        outline: none;
        border-radius: 5px;
        margin-bottom: 20px;
    }
    .full-order__show{
        margin-left: 20px;
    }
    .full-order__subheading{
        margin-bottom: 5px;
    }
    .full-order__change{
        margin-bottom: 25px;
    }
    .back{
        display: inline-block;
        padding: 15px 40px;
        background: #313131 url(../images/arrow-2.png) no-repeat;
        background-position: center 85%;
        border-radius: 4px;
        color: #fff;
        text-decoration: none;
    }
    .full-order__add-btns{
        display: flex;
        justify-content: space-between;
    }
    .full-order__addfile{
        outline: none;
        background-color: #D61921;
        border: 1px solid #D61921;
        max-height: 90px;
        color: #fff;
        height: 80px;
        width: 140px;
        border-radius: 5px;
        height: 60px;
    }
    .full-order__btn_red{
        background-color: #D61921;
        border-color: #b60009;
        display: none;
    }
    .btn__show{
        display: block;
    }
    .btn__showAdm{
        display: block;
    }
    .btn__hide{
        display: none;
    }
    .agent__name{
        margin-bottom: 5px;
    }
    .agent__mark{
        font-weight: 700;
    }
    .loading{
        pointer-events: none;
        background-color: #b8b8b8;
        border-color: #b8b8b8;
    }
    .full-order__agent{
        position: relative;
    }
    .agent__show{
        position: absolute;
        display: block;
        height: 40px;
        width: 40px;
        right: 0;
        top: 10px;
        background: url(http://localhost:8080/img/edit-ico.aba2e901.png) no-repeat;
        background-size: contain;
    }
    .agent__edit-form{
        display: flex;
        flex-direction: column;
    }
    .order-form__agent{
        position: relative;
        margin-top: 20px;
    }
    .agent__current{
        position: relative;
        padding: 8px 20px 8px 10px;
        border: 1px solid #000;
        border-radius: 2px;
        cursor: pointer;
    }
    .agent__current::after{
        position: absolute;
        content: "";
        top: 25%;
        right: 20px;
        height: 15px;
        width: 15px;
        border-right: 2px solid #000;
        border-bottom: 2px solid #000;
        transform: rotate(45deg);
        transition: all linear .2s;
    }
    .agent__items{
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 100%;
        right: 0;
        background-color: #EEE;
        padding: 0 10px;
        padding-bottom: 2px;
        width: 100%;
        z-index: 1000;
        max-height: 330px;
        overflow-y: scroll;
    }
    .agent__item{
        padding: 20px 10px;
        border-bottom: 1px solid #000;
        cursor: pointer;
    }
    .agent__item:hover{
        background-color: #d8d8d8;
    }
    .agent-form__btn{
        padding: 10px 35px;
        border: 1px solid #000;
        margin-top: 25px;
        align-self: center;
    }
</style>
