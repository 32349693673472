<template>
    <div class="filters">
        <div class="filters__inner">
            <StatusSelect class="filters__item"/>
            <OrderSelect class="filters__item"/>
            <UserSelect class="filters__item"/>
        </div>
    </div>
</template>

<script>
import StatusSelect from '@/components/filters/Status-select'
import OrderSelect from '@/components/filters/Order-select'
import UserSelect from '@/components/filters/User-select'

export default {
    components: {
        StatusSelect,
        OrderSelect,
        UserSelect
    }
}
</script>

<style scoped>
    .filters__item{
        margin-bottom: 20px;
    }
    .filters__item:last-child{
        margin-bottom: 0;
    }
</style>