<template>
  <router-view></router-view>
</template>

<style>
    *,
    *::before,
    *::after{
        box-sizing: border-box;
    }
    *{
        margin: 0;
        padding: 0;
    }
    body{
        font-family: 'Montserrat', sans-serif;
    }
    button{
        cursor: pointer;
        border: none;
        outline: none;
        background: none;
        font-size: 16px;
    }
    input{
        outline: none;
    }
    .loading{
        opacity: .5;
        pointer-events: none;
    }
    .checkbox__wrapper{
        display: flex;
        align-items: center;
    }
    .checkbox__ico{
        position: relative;
        display: inline-block;
        vertical-align: baseline;
        height: 20px;
        width: 20px;
        margin-right: 20px;
        margin-bottom: -2px;
        border: 1px solid #000;
        border-radius: 4px;
        cursor: pointer;
    }
    .checkbox__input{
        display: none;
    }
    .checkbox_select{
        background-color: #000;
        transition: all linear .1s;
    }
    .checkbox_select::before{
        position: absolute;
        content: "";
        height: 12px;
        width: 2px;
        top: 3px;
        right: 6px;
        transform: rotate(35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
    .checkbox_select::after{
        position: absolute;
        content: "";
        height: 7px;
        width: 2px;
        bottom: 4px;
        left: 5px;
        transform: rotate(-35deg);
        background-color: #fff;
        border-radius: 2px;
        transition: all linear .2s;
    }
</style>
