<template>
    <div class="order-form">
        <div class="order-form__inner">
            <form class="form order-form__form" @submit.prevent="createOrder">
                <h1 class="order-form__heading">Создание счета</h1>

                <label for="id" class="order-form__label">Номер счёта:</label>
                <input 
                    id="id" 
                    type="text" 
                    placeholder="458"
                    v-model="form.id"
                    class="order-form__input"
                    required>

                <label for="file1" class="order-form__label">Счет:</label>
                <input 
                    @change="handleFileUpload" 
                    id="file1" 
                    type="file" 
                    ref="file1"
                    class="order-form__file"
                    required>

                <label for="date" class="order-form__label">Дата:</label>
                <input 
                    id="date" 
                    type="date" 
                    v-model="form.created" 
                    class="order-form__date"
                    required>

                <!-- Добавление контрагента -->
                
                <div class="agent order-form__agent">
                    <div 
                        class="agent__current" @click="openMenu"
                        :class="{rotate:isMenuOpened}">{{agentName}}</div>

                    <div class="agent__items" v-if="isMenuOpened">
                        <span 
                            class="agent__item" 
                            v-for="option in AGENTS"
                            :key="option.id"
                            @click="selectOption(option)"
                            :class="{disabled:option.name == selectedAgent.name}"
                            >{{option.name}}</span>
                        <span 
                            class="agent__item"
                            @click="selectOption('')"
                            :class="{disabled:selectedAgent.name == 'Контрагент не выбран'}"
                            >Не выбран</span>
                    </div>
                </div>


                <label for="comments" class="order-form__label">Комментарии</label>
                <textarea 
                    rows="5"
                    id="comments" 
                    placeholder="Комментарии"
                    v-model="form.info"
                    class="order-info__comments"
                    required>
                </textarea>

                <div class="order-form__checkboxes checkboxes">
                    <div class="checkboxes__block">
                        <label for="pay">Необходимо платежное поручение</label>
                        <input v-model="form.needPayDoc" type="checkbox" id="pay">
                    </div>

                    <div class="checkboxes__block">
                        <label for="service">Оплата услуг</label>
                        <input v-model="form.isService" type="checkbox" id="service" @change="dropOne">
                    </div>
                    
                    <div class="checkboxes__block">
                        <label for="withDelivery">Без доставки</label>
                        <input v-model="form.withoutDelivery" type="checkbox" id="withDelivery" @change="dropTwo">
                    </div>

                    <div class="checkboxes__block">
                        <label for="hot">Срочный счет</label>
                        <input v-model="form.isHot" type="checkbox" id="hot" @change="dropThree">
                    </div>

                </div>
                
                <button 
                    type="submit" 
                    class="order-form__button"
                    :class="{loading: isLoading}">{{submitBtnText}}</button>
            </form>

            <div class="navigation order-page__navigation">
                <router-link to="/" 
                    class="navigation__link navigation__link_now">Текущие</router-link>
                <a href="#" class="navigation__link navigation__link_add active">Добавить</a>
                <router-link to="/archive"
                    class="navigation__link navigation__link_archive">Архив</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            form: {
                id: '',
                info: '',
                created: '',
                url: '',
                needPayDoc: false,
                isService: false,
                withoutDelivery: false,
                isHot: false,
            },
            selectedAgent: '',
            isMenuOpened: false,
            isLoading: false
        }
    },
    methods: {
        ...mapActions([
            'GET_AGENTS'
        ]),
        openMenu(){
            this.isMenuOpened = !this.isMenuOpened
        },
        createOrder(){
            this.isLoading = true
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            const formData = new FormData()
            formData.append('id', this.form.id)
            formData.append('info', this.form.info)
            formData.append('created', this.form.created)
            formData.append('url', this.form.url)
            formData.append('isService', this.form.isService)
            formData.append('withoutDelivery', this.form.withoutDelivery)
            formData.append('isHot', this.form.isHot)
            formData.append('needPayDoc', this.form.needPayDoc)
            if(this.selectedAgent != ''){
                let str = JSON.stringify(this.selectedAgent)
                formData.append('agentId', str)
            }
            
            axios({
                method: 'POST',
                url: 'https://superspiral.ru:3000/api/order/create',
                headers: { Authorization: `Bearer ${cookie[1]}`, "Content-Type": "multipart/form-data" },
                data: formData
            }).then((response)=>{
                if(response.data.message == 'Такой счет уже существует'){
                    return alert(response.data.message)
                }
            }).catch((error)=>{
                switch (error.response.data.message) {
                    case 'Нет доступа':
                        alert(error.response.data.message)
                        break;

                    default:
                        break;
                }
            }).then(()=>{
                location.reload()
            })
        },

        handleFileUpload(){
            this.form.url = this.$refs.file1.files[0];
        },
        dropOne(){
            this.form.withoutDelivery = false
            this.form.isHot = false
        },
        dropTwo(){
            this.form.isService= false
        },
        dropThree(){
            this.form.isService = false
        },
        selectOption(option){
            this.selectedAgent = option
            this.isMenuOpened = false
        }
    },
    computed: {
        ...mapGetters([
            'AGENTS'
        ]),
        submitBtnText(){
            return this.isLoading ? 'Загрузка...' : 'Создать'
        },
        agentName(){
            return this.selectedAgent == '' ? 'Контрагент не выбран' : this.selectedAgent.name
        }
    },
    mounted() {
        this.GET_AGENTS()
    },
}
</script>

<style scoped>
    .order-form__inner{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
        padding-bottom: 80px;
        max-width: 600px;
        margin: 0 auto;
    }
    .order-form__form{
        width: 100%;
        max-width: 600px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        border: 1px solid #b8b8b8;
        box-shadow: 0 4px 21px rgb(0 0 0 / 25%);
        border-radius: 5px;
    }
    .order-form__heading{
        text-align: center;
    }
    .order-form__label{
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .order-form__input{
        padding: 10px;
        font-size: 14px;
        outline: none;
    }
    .order-form__file{
        height: 35px;
        color: #000;
    }
    .order-form__date{
        height: 35px;
        color: #000;
    }
    .order-info__comments{
        padding: 5px;
    }
    .order-form__checkboxes{
        margin-top: 15px;
        display: flex;
        flex-direction: column;
    }
    .checkboxes__block{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
    }
    .order-form__button{
        border: 1px solid #009ED2;
        background: #009ED2;
        outline: none;
        color: #fff;
        border-radius: 2px;
        padding: 15px 0;
        font-size: 16px;
        font-weight: 700;
        margin-top: 30px;
    }
    .order-page__navigation{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        background-color: #eee;
        border-top: 1px solid #d8d8d8;
    }
    .navigation__link{
        flex-basis: 33.333%;
        position: relative;
        padding: 50px 25px 10px;
        font-size: 12px;
        text-decoration: none;
        color: #000;
        border-right: 1px solid #d8d8d8;
        text-align: center;
    }
    .navigation__link::before{
        position: absolute;
        content: "";
        width: 40px;
        height: 40px;
        top: 5px;
        left: 50%;
        margin-left: -20px;
    }
    .navigation__link:last-child{
        border-right: none;
    }
    .navigation__link_now::before{
        background: url(../images/now2.png) no-repeat;
        background-size: contain;
        background-position: center;
    }
    .navigation__link_add::before{
        background: url(../images/add4.png) no-repeat;
        background-size: contain;
        background-position: center;
    }
    .navigation__link_archive::before{
        background: url(../images/archive.png) no-repeat;
        background-size: contain;
        background-position: center;
    }
    .order-form__agent{
        position: relative;
        margin-top: 20px;
    }
    .agent__current{
        position: relative;
        padding: 8px 20px 8px 10px;
        border: 1px solid #000;
        border-radius: 2px;
        cursor: pointer;
    }
    .agent__current::after{
        position: absolute;
        content: "";
        top: 25%;
        right: 20px;
        height: 15px;
        width: 15px;
        border-right: 2px solid #000;
        border-bottom: 2px solid #000;
        transform: rotate(45deg);
        transition: all linear .2s;
    }
    .agent__items{
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 100%;
        right: 0;
        background-color: #EEE;
        padding: 0 10px;
        padding-bottom: 2px;
        width: 100%;
        z-index: 1000;
        max-height: 330px;
        overflow-y: scroll;
    }
    .agent__item{
        padding: 20px 10px;
        border-bottom: 1px solid #000;
        cursor: pointer;
    }
    .agent__item:hover{
        background-color: #d8d8d8;
    }
    .active{
        background-color: #d8d8d8;
        pointer-events: none;
    }
    .rotate::after{
        transform: rotate(225deg);
    }
    .loading{
        pointer-events: none;
        background-color: #b8b8b8;
        border-color: #b8b8b8;
    }
</style>