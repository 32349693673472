<template>
    <div class="order-page">
        <div class="order-page__inner">
            <div class="header header__offset">
                <div class="order-page__left">
                    <a 
                    class="header__support" 
                    href="https://t.me/+QCFmqwZEnl40M2Iy"
                    target="_blank">Поддержка</a>

                    <router-link 
                        to="/agents"
                        class="header__agent">Контрагенты</router-link>
                </div>
                
                <span class="header__role" @click="exit">{{userName}}</span>
            </div>
            <div class="admin-link" v-if="this.role == 'ADMIN'">
                <router-link to="/create" class="admin-link__link">Создать пользователя</router-link>
            </div>
            <h1 class="order-page__heading" id="top">Текущие счета</h1>

            <div class="search">
                <div class="search-inner">
                    <input 
                        placeholder="Поиск..." 
                        class="search__field"
                        @input="getGuesses" 
                        @keyup.enter="search(searchValue)"
                        v-model="searchValue">

                    <button 
                        @click="search(searchValue)" 
                        class="search__button"></button>
                </div>

                <div v-if="searchValue" class="search__guesses">
                    <div v-if="sortSearch == 'num'">
                        <p class="search__guess"
                        v-for="guess in guesses"
                        @click="selectGuess(guess.id)"
                        :key="guess.index">{{guess.id}}</p>
                    </div>

                    <div v-if="sortSearch == 'ypd'">
                        <p class="search__guess"
                        v-for="guess in guesses"
                        @click="selectGuess(guess.ypd)"
                        :key="guess.index">{{guess.ypd}}</p>
                    </div>

                    <div v-if="sortSearch == 'date'">
                        <p class="search__guess"
                        v-for="guess in guesses"
                        @click="selectGuess(guess.created)"
                        :key="guess.index">{{guess.created}}</p>
                    </div>
                </div>

                <div class="search__checkboxes">
                    <div class="checkbox checkbox__wrapper">
                        <span 
                            class="checkbox__ico"
                            :class="{checkbox_select: isNumber}"
                            @click="selectNumber"></span>
                        <input 
                            id="checkbox" 
                            type="checkbox" 
                            class="checkbox__input">

                        <label 
                            for="checkbox" 
                            class="checkbox__label"
                            @click="selectNumber">По номеру счета</label>
                    </div>
                    
                    <div class="checkbox checkbox__wrapper">
                        <span 
                            class="checkbox__ico"
                            :class="{checkbox_select: isYpd}"
                            @click="selectYpd"></span>
                        <input 
                            id="checkbox" 
                            type="checkbox" 
                            class="checkbox__input">

                        <label 
                            for="checkbox" 
                            class="checkbox__label"
                            @click="selectYpd">По номеру УПД</label>
                    </div>

                    <div class="checkbox checkbox__wrapper">
                        <span 
                            class="checkbox__ico"
                            :class="{checkbox_select: isDate}"
                            @click="selectDate"></span>
                        <input 
                            id="checkbox" 
                            type="checkbox" 
                            class="checkbox__input">

                        <label 
                            for="checkbox" 
                            class="checkbox__label"
                            @click="selectDate">По дате счета</label>
                    </div>
                </div>
            </div>
            <button 
                class="order-page__filters-btn"
                :class="{filterActive: isFiltersOpened}"
                type="button"
                @click="openFilters">Фильтры</button>

            <div class="order-page__filter" v-if="isFiltersOpened">
                <Filters/>
            </div>
            
            <Order 
                v-for="order in ORDERS"
                :key="order.id"
                :order_data="order"
                @openOrder="openOrder"
                />

            <div class="pagination order-page__pagination">
                <a href="#top" 
                class="page"
                v-for="page in TOTAL_PAGES"
                :key="page"
                :class="{page__selected:page === this.$store.state.page}"
                @click="pageClick(page)">{{page}}</a>
            </div>

            <div class="navigation order-page__navigation">
                <a 
                    href="#" 
                    class="navigation__link navigation__link_now active">Текущие</a>
                <router-link to="/order" class="navigation__link navigation__link_add">Добавить</router-link>
                <router-link to="/archive" 
                    class="navigation__link navigation__link_archive">Архив</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions ,mapGetters} from 'vuex'
import Order from '@/components/Order'
import Filters from '@/components/filters/Filters'
import axios from 'axios'

export default {
    data() {
        return {
            role: '',
            userName: '',
            searchValue: '',
            guesses: '',
            sortSearch: 'num',
            isNumber: true,
            isYpd: false,
            isDate: false,
            isFiltersOpened: false
        }
    },
    components:{
        Order,
        Filters
    },
    methods: {
        ...mapActions([
            'GET_ORDERS',
            'GET_PAGE_NUMBER',
            'RESET_PAGE_NUMBER',
            'SEARCH_CUR_ORDER',
            'IS_ARCHIVE',
            'GET_EMPLOYEES',
            'GET_SORT_SEARCH'
        ]),
        openOrder(id){
            this.$router.push({name: 'curorder', query: {'curorder': id}})
        },
        openFilters(){
            this.isFiltersOpened = !this.isFiltersOpened
        },
        exit(){
            let isExit = confirm("Выйти?");
            if(isExit){
                document.cookie = `token = ''; max-age=-1`
                this.$router.push('/login')
            } 
        },
        pageClick(page){
            this.GET_PAGE_NUMBER(page)
            this.GET_ORDERS()
        },
        getRole(){
            this.role = localStorage.getItem('role')
            this.userName = localStorage.getItem('name')
        },
        selectNumber(){
            this.isNumber = true
            this.isYpd = false
            this.isDate = false
            this.sortSearch = 'num'
            this.GET_SORT_SEARCH('num')
        },
        selectYpd(){
            this.isYpd = true
            this.isNumber = false
            this.isDate = false
            this.sortSearch = 'ypd'
            this.GET_SORT_SEARCH('ypd')
        },
        selectDate(){
            this.isDate = true
            this.isYpd = false
            this.isNumber = false
            this.sortSearch = 'date'
            this.GET_SORT_SEARCH('date')
        },
        search(value){
            this.SEARCH_CUR_ORDER(value)
            this.searchValue = ''
        },
        getGuesses(){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios({
                method: 'GET',
                url: 'https://superspiral.ru:3000/api/order/getguess',
                headers: { Authorization: `Bearer ${cookie[1]}`},
                params: {
                    searchVal: this.searchValue,
                    isArchive: 0,
                    sort: this.sortSearch
                }
            }).then((res)=>{
                this.guesses = res.data
            })
        },
        selectGuess(val){
            this.searchValue = val
        }
    },
    computed: {
        ...mapGetters([
            'ORDERS',
            'TOTAL_PAGES'
        ])
    },
    mounted() {
        this.IS_ARCHIVE(0)
        // this.RESET_PAGE_NUMBER(1)
        this.GET_ORDERS(); 
        this.GET_EMPLOYEES()
        this.getRole()
        // window.scrollTo(0, 0)
    },
}
</script>

<style scoped>
    .order-page__inner{
        padding: 10px 15px;
        padding-bottom: 80px;
        max-width: 600px;
        margin: 0 auto;
        border: 2px solid #000;
        border-radius: 2px;
        margin-top: 15px;
        background-color: #eee;
    }
    .admin-link{
        text-align: right;
        border-bottom: 1px solid #000;
    }
    .admin-link__link{
        display: inline-block;
        text-decoration: none;
        margin-top: 15px;
        margin-bottom: 15px;
        padding: 10px;
        background-color: #009ED2;
        color: #fff;
        border-radius: 5px;
    }
    .header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #000;
    }
    .header__offset{
        padding-bottom: 15px;
    }
    .order-page__left{
        display: flex;
        align-items: center;
    }
    .header__agent{
        position: relative;
        padding-top: 40px;
        font-size: 12px;
        color: #000;
        text-decoration: none;
    }
    .header__agent::before{
        position: absolute;
        content: "";
        height: 40px;
        width: 40px;
        top: 0;
        left: 50%;
        margin-left: -20px;
        background: url(../images/agent.png) no-repeat;
        background-size: cover;
    }
    .header__support{
        position: relative;
        padding-top: 40px;
        font-size: 12px;
        color: #000;
        text-decoration: none;
        margin-right: 20px;
    }
    .header__support::before{
        position: absolute;
        content: "";
        height: 40px;
        width: 40px;
        top: 0;
        left: 15px;
        background: url(../images/support-ico.png) no-repeat;
        background-size: cover;
    }
    .header__role{
        position: relative;
        padding: 15px 45px 25px 0;
    }
    .header__role::after{
        position: absolute;
        content: "";
        height: 35px;
        width: 35px;
        right: 0;
        top: 50%;
        margin-top: -20px;
        background: url(../images/exit.png) no-repeat;
        background-size: contain;
    }
    .order-page__heading{
        text-align: center;
        margin-top: 15px;
    }
    .search{
        position: relative;
        height: 100%;
        width: 100%;
        margin-bottom: 25px;
    }
    .search-inner{
        display: flex;
        border: 1px solid #000;
        border-radius: 5px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .search__checkboxes{
        display: flex;
        justify-content: space-between;
    }
    .order-page__filters-btn{
        background: none;
        font-size: 14px;
        padding: 15px 30px;
        border: 1px solid #000;
        margin-bottom: 15px;
    }
    .order-page__filters-btn:hover{
        background-color: #d8d8d8;
    }
    .search__field{
        border: none;
        border-radius: 5px 0 0 5px;
        padding: 15px 25px 15px 20px;
        width: 100%;
        height: 100%;
        transition: all linear .1s;
    }
    .search__field:focus{
        outline: none;
    }
    .search__button{
        background: none;
        border: none;
        border-left: 1px solid #000;
        width: 55px;
        background: url(../images/search-ico.png) no-repeat;
        background-position: center;
        background-size: 30px 30px;
        border-radius: 0 5px 5px 0;
    }
    .search__button:hover{
        cursor: pointer;
    }
    .search__guesses{
        position: absolute;
        background-color: #fff;
        box-shadow: 0 10px 20px rgb(0 0 0 / 50%);
        padding: 15px;
        width: 100%;
        z-index: 100;
        max-height: 330px;
        overflow-y: scroll;
    }
    .search__guess{
        margin-top: 15px;
        font-size: 14px;
        padding: 5px;
    }
    .search__guess:hover{
        cursor: pointer;
        background-color: #b6b4b4;
    }
    .pagination{
        display: flex;
        justify-content: center;
    }
    .order-page__pagination{
        margin-top: 25px;
        margin-bottom: 25px;
    }
    .page{
        height: 40px;
        width: 40px;
        line-height: 40px;
        background-color: #D8D8D8;
        text-align: center;
        margin-right: 10px;
        transition: all linear .2s;
        border-radius: 5px;
        color: #000;
        text-decoration: none;
    }
    .page:hover{
        cursor: pointer;
        background-color: #b6b4b4;
    }
    .page__selected{
        background-color: #b6b4b4;
    }

    .order-page__navigation{
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        background-color: #eee;
        border-top: 1px solid #d8d8d8;
    }
    .navigation__link{
        flex-basis: 33.333%;
        position: relative;
        padding: 50px 25px 10px;
        font-size: 12px;
        text-decoration: none;
        color: #000;
        border-right: 1px solid #d8d8d8;
        text-align: center;
    }
    .navigation__link::before{
        position: absolute;
        content: "";
        width: 40px;
        height: 40px;
        top: 5px;
        left: 50%;
        margin-left: -20px;
    }
    .navigation__link:last-child{
        border-right: none;
    }
    .navigation__link_now::before{
        background: url(../images/now2.png) no-repeat;
        background-size: contain;
        background-position: center;
    }
    .navigation__link_add::before{
        background: url(../images/add4.png) no-repeat;
        background-size: contain;
        background-position: center;
    }
    .navigation__link_archive::before{
        background: url(../images/archive.png) no-repeat;
        background-size: contain;
        background-position: center;
    }
    .active{
        background-color: #d8d8d8;
        pointer-events: none;
    }
    .filterActive{
        background-color: #d8d8d8;
    }
    @media (max-width: 600px) {
        .search__checkboxes{
            flex-direction: column;
        }
        .checkbox__wrapper{
            margin-bottom: 25px;
        }
    }
</style>