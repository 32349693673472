<template>
    <div class="agent-page">
        <div class="agent-page__inner">
            <div class="agent-page__header">
                <router-link to="/" class="agent-page__back">Назад</router-link>
            </div>
            <h1 class="agent-page__heading">Контрагенты</h1>
            <button 
                class="agent-page__add"
                type="button"
                :class="{formActive: isFormOpened}"
                @click="toggleForm">Добавить контрагента</button>

            <form class="agent-page__add-form form" v-if="isFormOpened">
                <h3 class="form__heading">Добавление контрагента</h3>
                <input 
                    class="form__input"
                    placeholder="название контрагента"
                    v-model="agentName">

                <input 
                    class="form__input"
                    placeholder="Инфо"
                    v-model="agentAddress">

                <button 
                    class="form__btn" 
                    :class="{loading: isLoading}"
                    @click="addAgent"
                    type="button">{{btnText}}</button>
            </form>

            <div class="agent-page__agents agents">
                <AgentItem
                    v-for="agent in AGENTS"
                    :key="agent.id"
                    :agent_data="agent"
                    class="agents__item"/>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters} from 'vuex'
import AgentItem from '@/components/agents/Agent-item'

export default {
    data() {
        return {
            isFormOpened: false,
            agentName: '',
            agentAddress: '',
            isLoading: false
        }
    },
    components: {
        AgentItem
    },
    computed: {
        ...mapGetters([
            'AGENTS'
        ]),
        btnText(){
            return this.isLoading ? 'Загрузка...' : 'Добавить'        
            }
    },
    methods: {
        ...mapActions([
            'GET_AGENTS'
        ]),
        toggleForm(){
            this.isFormOpened = !this.isFormOpened
        },
        addAgent(){
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            this.isLoading = true
            axios({
                method: 'POST',
                url: 'https://superspiral.ru:3000/api/agent/create',
                headers: {Authorization: `Bearer ${cookie[1]}`},
                data: {
                    name: this.agentName,
                    address: this.agentAddress,
                }
            }).then(()=>{
                location.reload()
            })
        }
    },
    mounted() {
        this.GET_AGENTS()
    },
}
</script>

<style scoped>
    .agent-page__inner{
        padding: 10px 15px;
        padding-bottom: 80px;
        max-width: 600px;
        margin: 0 auto;
        border: 2px solid #000;
        border-radius: 2px;
        margin-top: 15px;
    }
    .agent-page__header{
        display: flex;
        margin-bottom: 25px;
    }
    .agent-page__heading{
        font-size: 28px;
        margin-bottom: 25px;
        text-align: center;
    }
    .agent-page__back{
        text-decoration: none;
        padding: 10px 15px;
        border: 1px solid #000;
        border-radius: 5px;
        color: #000;
    }
    .agent-page__add{
        display: block;
        width: 100%;
        padding: 10px;
        border: 1px solid #000;
        transition: all linear .2s;
        border-radius: 5px;
    }
    .agent-page__add:hover{
        background-color: #eee;
    }
    .agent-page__add-form{
        display: flex;
        flex-direction: column;
        margin-top: 25px;
        padding-bottom: 20px;
        border-bottom: 1px solid #000;
    }
    .form__heading{
        margin-bottom: 15px;
        text-align: center;
    }
    .form__input{
        margin-bottom: 20px;
        padding: 10px;
    }
    .form__btn{
        padding: 10px 45px;
        border: 1px solid #000;
        align-self: center;
        border-radius: 5px;
    }
    .agent-page__agents{
        margin-top: 30px;
    }
    .agents__item{
        margin-bottom: 25px;
    }
    .formActive{
        background-color: #eee;
    }
</style>