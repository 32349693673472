<template>
    <div class="order" :class="{isNeedPayDoc: order_data.needPayDoc}">
        <div 
            class="order__inner" 
            @click="openOrder" 
            :class="{yellow: order_data.creatorRole =='Офис' || order_data.creatorRole =='Бухгалтерия', green: order_data.creatorRole =='Директор', red: isExpired}">
            <span class="order__hot" v-if="order_data.isHot">Приоритетный счет</span>
            <h1 class="order__heading">Номер счета: {{order_data.id}}</h1>
            <p class="order__date">От: {{order_data.created}}</p>
            <p class="full-order__ypd">Номер УПД: {{order_data.ypd}}</p>
            <p class="full-order__ypd-date">От: {{order_data.ypdCreatedBy}}</p>
            <div v-if="!order_data.isService">
                <span class="order__delivery" v-if="!order_data.withoutDelivery">С доставкой</span>
                <span class="order__delivery" v-else>Без доставки</span>
            </div>
            <span class="order__service" v-if="order_data.isService">(Оплата услуг)</span>

            <p 
                class="order__waiting"
                v-if="order_data.status == 'Оплачено' && !order_data.withoutDelivery">Ожидание готовности к доставке</p> 

            <p class="order__status">Статус счета: {{order_data.status}}</p>
            
            <div class="order__notification">
                <p class="order__info">{{creator}}</p>
                <p class="order__info" v-if="isExpired">Просрочен</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            isExpired: false
        }
    },
    props: {
        order_data: {
            type: Object,
            default(){
                return{

                }
            }
        },
    },
    methods: {
        openOrder(){
            this.$emit('openOrder', this.order_data.index)
        },
        checkOverdue(){
            let currentData = new Date().toISOString().slice(0, 10)
            let curDateParse = Date.parse(currentData)

            let orderDate = this.order_data.changedBy6
            let orderDateParse = Date.parse(orderDate)

            let days = Math.floor(Math.abs(curDateParse-orderDateParse)/(1000*60*60*24))

            if(days >= 3){
                this.isExpired = true
            }
            // let orderDateParse = Date.parse(this.order_data.updatedAt)

            // let days = Math.floor(Math.abs(curDateParse-orderDateParse)/(1000*60*60*24))

            // if(this.order_data.status == 'УПД передано в бухгалтерию' && days >= 3){
            //     this.isExpired = true
            // }
        }
    },
    computed: {
        creator(){
            switch (this.order_data.creatorRole) {
                case "Склад":
                    return "Производство"
                case "Главный инженер":
                    return "Производство"
                case "Инженер":
                    return "Производство"
                case "Офис":
                    return "Офис"
                case "Бухгалтерия":
                    return "Бухгалтерия"
                case "Директор":
                    return "Директор"
                default:
                    break;
            }
        }
    },
    mounted() {
        if (this.order_data.status == 'УПД передано в бухгалтерию') {
            this.checkOverdue()
        }   
    },
}
</script>

<style scoped>
    .order__inner{
        max-width: 600px;
        margin: 25px 0;
        padding: 10px;
        width: 100%;
        box-shadow: 4px 4px 10px rgb(0 0 0 / 25%);
        border: 1px solid #000;
        border-radius: 5px;
        background-color: #fff;
    }
    .order__heading{
        font-size: 18px;
    }
    .full-order__ypd{
        margin-top: 10px;
        font-size: 14px;
    }
    .full-order__ypd-date{
        font-size: 14px;
        margin-bottom: 10px;
    }
    .order__service{
        display: inline-block;
        font-size: 14px;
        margin: 5px 0;
        padding-bottom: 2px;
        border-bottom: 1px solid #000;
    }
    .order__delivery{
        display: inline-block;
        font-size: 14px;
        margin: 5px 0;
        padding-bottom: 2px;
        border-bottom: 1px solid #000;
    }
    .order__date{
        font-size: 14px;
        margin-bottom: 5px;
    }
    .order__status{
        font-size: 14px;
        padding-bottom: 10px;
        border-bottom: 1px solid #000;
    }
    .order__waiting{
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 5px;
    }
    .order__notification{
        display: flex;
        justify-content: space-between;
    }
    .order__info{
        font-size: 14px;
        font-style: italic;
        margin-top: 10px;
    }
    .order__hot{
        color: red;
        display: inline-block;
        font-weight: 700;
        margin-bottom: 5px;
    }
    .yellow{
        background-color: rgba(230, 230, 0, .6);
    }
    .green{
        background-color: rgba(33, 201, 0, 0.6);
    }
    .red{
        background-color: rgba(255, 0, 0, 0.5);
    }
    .isNeedPayDoc{
        background: url(../images/pay-dock-ico.png) no-repeat;
        background-size: 40px 40px;
        background-position: 98% 10px;
    }
</style>
