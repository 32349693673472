<template>
    <div class="admin-form">
        <div class="admin-form__inner">
            <form class="admin-form admin-form__form" @submit.prevent="submit" onsubmit="return false;">
                <h1 class="admin-form__heading">Добавление сотрудника</h1>
                <label for="name" class="admin-form__label">Имя, Фамилия:</label>
                <input 
                    type="text" 
                    class="admin-form__input" 
                    placeholder="Иван Петров"
                    id="name"
                    v-model="form.name"
                    required>

                <label for="role" class="admin-form__label">Должность сотрудника:</label>
                <select id="role" class="admin-form__select" required v-model="form.role">
                    <option disabled selected value> -- Выберите должность -- </option>
                    <option>Инженер</option>
                    <option>Главный инженер</option>
                    <option>Склад</option>
                    <option>Бухгалтерия</option>
                    <option>Офис</option>
                    <option>Директор</option>
                </select>

                <label for="login" class="admin-form__label">Создайте логин для сотрудника:</label>
                <input 
                    type="text" 
                    class="admin-form__input" 
                    placeholder="Например: ivanov86"
                    id="login"
                    v-model="form.login"
                    required>

                <label for="pass" class="admin-form__label">Создайте или сгенерируйте пароль:</label>
                <input 
                    type="text" 
                    class="admin-form__input"
                    v-model="form.password"
                    required>
                <button
                    type="button" 
                    class="admin-form__generate" 
                    @click="generatePass">Сгенерировать пароль</button>

                <button 
                    type="submit" 
                    class="admin-form__btn"
                    :class="{loading: isUserLoading}">{{submitBtnText}}</button>
                <router-link to="/employees" class="admin-form__link">Открыть список сотрудников</router-link>
                <router-link to="/" class="back admin-form__back">Назад</router-link>
            </form>
            
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data() {
        return {
            form: {
                name: '',
                role: '',
                login: '',
                password: '',
            },
            isUserLoading: false,
            passSymbols: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz!@#$%^&*()_=+'
        }
    },
    methods: {
        generatePass(){
            this.form.password = ''
            let randomSymbol =''
            for(let i = 0; i < 12; i++){
                randomSymbol = Math.floor(Math.random()*this.passSymbols.length)
                this.form.password += this.passSymbols[randomSymbol]
            }
        },
        submit(){
            this.isUserLoading = true
            let cookie = document.cookie.match(/token=(.+?)(;|$)/);
            axios({
                method: 'POST',
                url: 'https://superspiral.ru:3000/api/user/registration',
                headers: {Authorization: `Bearer ${cookie[1]}`},
                data: {
                    name: this.form.name,
                    role: this.form.role,
                    login: this.form.login,
                    password: this.form.password
                }
            }).catch((error)=>{
                switch (error.response.data.message) {
                    case 'Нет доступа':
                        alert(error.response.data.message)
                        break;
                
                    default:
                        break;
                }
                console.log(error.response.data);
            }).then(()=>{
                location.reload()
            })
        }
    },
    computed: {
        submitBtnText(){
            return this.isUserLoading ? 'Загрузка...' : 'Добавить'
        }
    }
}
</script>

<style scoped>
    .admin-form__inner{
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
    }
    .admin-form__form{
        max-width: 600px;
        display: flex;
        flex-direction: column;
        padding: 20px;
        border: 1px solid #b8b8b8;
        box-shadow: 0 4px 21px rgb(0 0 0 / 25%);
        border-radius: 5px;
    }
    .admin-form__heading{
        text-align: center;
    }
    .admin-form__label{
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .admin-form__input{
        padding: 10px;
        font-size: 14px;
        outline: none;
    }
    .admin-form__select{
        -webkit-appearance: none;
        background-image: url(../../images/arrow.png);
        background-size: 25px 25px;
        background-repeat: no-repeat;
        background-position: 95% center;
        padding: 10px;
        outline: none;
        font-size: 14px;
        color: #000;
        border-radius: 2px;
    }
    .admin-form__generate{
        padding: 10px 0;
        font-size: 16px;
        background: #fff;
        margin-top: 10px;
        border: 1px solid #000;
        background: #eee;
        color: #000;
        border-radius: 2px;
    }
    .admin-form__btn{
        border: 1px solid #009ED2;
        background: #009ED2;
        outline: none;
        color: #fff;
        border-radius: 2px;
        padding: 15px 0;
        font-size: 16px;
        font-weight: 700;
        margin-top: 50px;
    }
    .admin-form__link{
        text-align: center;
        text-decoration: none;
        background-color: #01ADC0;
        padding: 15px 0;
        margin-top: 20px;
        color: #fff;
        border-radius: 2px;
        font-weight: 700;
    }
    .loading{
        pointer-events: none;
        background-color: #b8b8b8;
        border-color: #b8b8b8;
    }
    .back{
        display: inline-block;
        padding: 15px 40px;
        background: #313131 url(../../images/arrow-2.png) no-repeat;
        background-position: center 85%;
        border-radius: 4px;
        color: #fff;
        text-decoration: none;
        margin-top: 20px;
    }
    .admin-form__back{
        align-self: flex-start;
        margin-top: 40px;
    }
</style>